import { useEffect } from "react";
import { useGoogleReCaptcha } from "react-google-recaptcha-v3";
import { Helmet } from "react-helmet";
import { useMutation } from "@tanstack/react-query";
import { useFormik } from "formik";
import Cookies from "js-cookie";

import { Button, TextInput, Typography, TypographyLink, useToast } from "design2impact";
import { checkCookie, redirectByRole } from "helpers2impact";

import { loginValidators } from "./validators";

import { login } from "api/mutations";

import config from "config";


interface FormInitialValues {
	email: string;
	password: string;
}


const formInitialValues: FormInitialValues = { email: "", password: "" };

const Login = () => {
	const { showToast } = useToast();
	const cookie = checkCookie(config.cookieName);

	const { executeRecaptcha } = useGoogleReCaptcha();

	const {
		isPending,
		mutate: loginFn,
	} = useMutation({
		mutationFn: login,
		onSuccess: (data) => {
			const domain = window.location.host.includes("localhost") ? "localhost" : ".start2impact.it";
			Cookies.set(config.cookieName, data.token, { expires: config.cookieExpirationDays, domain });
			redirectByRole(config.cookieName, config.adminUrl, config.talentUrl, config.coachUrl);
		},
		onError: () => showToast("Credenziali Email o password non valide.", "error"),
	});

	const handleLogin = async (data: FormInitialValues) => {
		if (!executeRecaptcha) {
			showToast("Impossibile Accedere", "error");
			return;
		}

		const recaptcha = await executeRecaptcha("login");
		const referrersId = Cookies.get("referrers");

		const loginData = { ...data, guest_identifier: referrersId || null, recaptcha };
		loginFn(loginData);
	};

	const formik = useFormik({
		initialValues: formInitialValues,
		onSubmit: (data) => {
			handleLogin(data);
		},
		validationSchema: loginValidators,
	});

	useEffect(() => {
		if (cookie) {
			const now = new Date();
			const expiredDate = now.getTime() + cookie.exp;
			const isExpired = expiredDate < now.getTime();
			isExpired ? Cookies.remove(config.cookieName) : redirectByRole(config.cookieName, config.adminUrl, config.talentUrl, config.coachUrl);
		}
	}, [cookie]);

	return (
		<>
			<Helmet>
				<title>Login | start2impact</title>
			</Helmet>

			<main className="min-h-screen bg-ui-secondary px-4 py-14 md:py-36 lg:py-20">

				<div className="flex flex-col md:mx-auto md:max-w-[500px]">

					<img src="https://res.cloudinary.com/start2impact/image/upload/h_64/logo/Logo_University-12_wa6eoi.png" alt="" height="64" width="64" className="self-center" />
					<Typography variant="h3" className="mt-6 md:text-center">Accedi a start2impact</Typography>
					<form onSubmit={formik.handleSubmit} noValidate className="mt-8 flex flex-col gap-5">
						<TextInput
							onChange={formik.handleChange}
							type="email"
							id="email"
							name="email"
							placeholder="Inserisci la tua email"
							isInvalid={!!(formik.errors.email && formik.touched.email)}
							errorMessage={formik.errors.email}
							label="Email*"
						/>
						<div>
							<TextInput
								onChange={formik.handleChange}
								type="password"
								id="password"
								name="password"
								placeholder="Inserisci la password"
								isInvalid={!!(formik.errors.password && formik.touched.password)}
								errorMessage={formik.errors.password}
								label="Password*"
								showPasswordButton={false}
							/>
							<TypographyLink isInternal to={{ pathname: "/password-dimenticata" }} variant="link" className="mt-3">
								Hai dimenticato la password?
							</TypographyLink>
						</div>

						<Button as="button" disabled={isPending} type="submit" className="mx-auto mt-3 w-fit">Accedi</Button>

						<TypographyLink to={{ pathname: config.signupPath }} isInternal variant="link" className="text-center">
							Non hai un account? REGISTRATI ORA
						</TypographyLink>
					</form>
				</div>
			</main>

		</>
	);
};

export default Login;
